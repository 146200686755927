
<h2>Angefragte Route wird geladen.</h2>

<ng-container *ngIf="error_message === ''">
  <p>
    Die per Link angefrage Route wird vom Server geladen.
  </p>
</ng-container>
<ng-container *ngIf="error_message !== ''" class="error_message">
  <p class="error_message">
    Die Route wurde nicht gefunden.
  </p>
</ng-container>

<ng-container *ngIf="error_message !== ''">
  <button mat-stroked-button color="warn" routerLink="/">Neuer Versuch starten</button>
</ng-container>

<app-map-background>


  <router-outlet>
  </router-outlet>
  <app-footer></app-footer>


</app-map-background>



<h2>Export läuft, wir bitten um Geduld.</h2>

<ng-container *ngIf="statusManager.status != 'error' else onerror">
  <mat-progress-bar mode="query"></mat-progress-bar>
</ng-container>
<ng-template #onerror>
  <mat-progress-bar  mode="buffer" value="80" color="warn"></mat-progress-bar>
</ng-template>

<p> Marschzeittabelle wird erstellt, wir bitten um Geduld.</p>
<p *ngFor="let old_state of statusManager.history" class="old_state">
  &nbsp;- <b>{{old_state?.last_change}}:</b> {{old_state?.message}}
</p>
<p id="status_message" [ngClass]="{'error_message': statusManager.status == 'error'}"><b>Status: </b>{{statusManager.status_message}} <i> ({{statusManager.status}})</i></p>


<ng-container *ngIf="statusManager.status == 'error'">
  <button mat-stroked-button color="warn" routerLink="/">Neuer Versuch starten</button>
</ng-container>
